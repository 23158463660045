body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* globalStyles.css */

/* Define custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar for horizontal scroll */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color on hover */
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Background color of the scrollbar track */
  border-radius: 4px;
}

/* Optionally, customize the overflow behavior */
body {
  overflow-y: scroll; /* Always show vertical scrollbar */
  overflow-x: hidden; /* Disable horizontal scrollbar */
}
